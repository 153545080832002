<template>
  <div>
    <nav-bar header-title="上团课 为心爱的教练打call" backColor="#fff" :show-back="true" :showShare="true" @right-txt-click="shareFun('self')" :header-back="newAppBack" />
    <div class="page-content">
      <div @click="ruleShow = true" class="rule-btn row-start-center f24">活动规则</div>
      <div @click="mxShow = true" class="mx-btn row-start-center f24">票数明细</div>

      <!-- 剩余票数 -->
      <p class="less-ticket f36 puhui">{{ activityInfo.userVotes }}</p>

      <div class="list-content">
        <div class="rank col-start-center">
          <div class="city-box row-center-center">
            <div @click="chooseCity(city)" :class="['city-item f30 fw6 row-center-center flex-auto', curCity.id === city.id ? 'active' : '']" v-for="city in cityList" :key="city.id">{{ city.text }}</div>
          </div>
          <div v-for="(i, index) in activityInfo.teacherList" :key="index" class="rank-item row-start-center f22">
            <span class="mc f34">
              <img :src="`https://img.chaolu.com.cn/ACT/teamWarband-202308/${index + 1}.png`" alt="" v-if="index < 3" />
              <span v-else>{{ index + 1 }}</span>
            </span>
            <span class="avt mr24">
              <img :src="i.teacherImg" alt="" />
            </span>
            <span class="pub_onefull mr20">
              <p class="f28 fw6 mt10">{{ i.teacherName }}</p>
              <p class="f22 mt8">{{ i.votes }}票</p>
            </span>
            <span class="row-start-center f24 fw6">
              <div @click="pullShare(i)" class="pull-btn row-center-center">拉票</div>
              <div @click="vote(i)" class="vote-btn row-center-center">投TA</div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 规则 -->
    <van-popup v-model="ruleShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius close" close-icon="https://img.chaolu.com.cn/ACT/teamWarband-202308/close2.png">
      <p class="rule-title f32 fw6">活动规则</p>
      <div class="rule-box f26">
        {{ rule }}
      </div>
    </van-popup>

    <!-- 打call教练 -->
    <van-popup v-model="voteShow" class="vote-popup" @close="voteShow = false">
      <div class="vote-box col-start-center">
        <img :src="curTeacher.teacherImg" alt="" class="avator" />
        <p class="name f32 fw6">{{ curTeacher.teacherName }}</p>
        <p class="say f24">感谢您的认可！动动发财的手指<br />为我打CALL吧！</p>
        <p class="tip f24">（我还可投{{activityInfo.userVotes}}票）</p>
        <div class="button-box row-between-center">
          <div @click="voteConfirm(true)" class="button-1 f32 row-center-center">全部投给TA</div>
          <div @click="voteConfirm(false)" class="button-2 f32 fw6 row-center-center">投1票</div>
        </div>
      </div>
      <img @click="voteShow = false" class="close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt="" />
    </van-popup>

    <!-- 明细 -->
    <van-popup v-model="mxShow" position="bottom" safe-area-inset-bottom class="mx-pop">
      <div class="pop-title row-center-center f36 fw6">
        <span class="ts">票数明细</span>
        <van-icon name="cross" class="pop-close" color="#fff" @click="mxShow = false" />
      </div>
      <p class="less f24">剩余票数：{{ activityInfo.userVotes }}</p>
      <div class="records">
        <div class="item" v-for="item in voteRecord" :key="item.createDate">
          <div class="t row-between-center">
            <div>
              <p class="f28 fw6">{{ item.content }}</p>
              <p class="p2 f22">{{ item.createDate }}</p>
            </div>
            <div class="flex-none">
              <p :class="['f28 fw6', item.type ? 'red' : '']">{{ item.type ? '-' : '+' }}{{ item.voteValue }}</p>
            </div>
          </div>
        </div>
        <p v-if="voteRecord.length === 0" class="empty col-center-center f28">
          <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt="" />
          暂无记录
        </p>
      </div>
    </van-popup>

    <!-- 分享 -->
    <common-share :value="showShare" :shareParams="shareParams" @close="showShare = false" @share-success="showShare = false" :shareItem="shareItem" :miniSharePoster="appTypeStr === 'mini'" :reload="true">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/teamWarband-202308/250103poster.png" class="canvascss_bg" />
          <div class="canvascss_left">
            <img :src="shareType === 'self' ? userDetail.headImg : curTeacher.teacherImg" />
          </div>
          <div class="canvascss_name ellipsis">
            <p class="f26 fw6 ellipsis">{{ shareType === 'self' ? userDetail.nickName : curTeacher.teacherName }}</p>
            <p class="f20 p2">{{ shareType === 'self' ? '跟我一起为教练打call吧~' : '快来为我投票打call吧~' }}</p>
          </div>
          <img class="canvascss_right" v-if="userDetail.qrCode" :src="'data:image/png;base64,' + userDetail.qrCode" />
        </div>
      </template>
    </common-share>
  </div>
</template>

<script>
import { newAppBack, initBack, appType, hideAppBackBtn } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import userMixin from '@/mixin/userMixin'
import commonShare from '@/components/commonShare/newShare'
import { getParam } from '@/lib/utils'
import wx from 'weixin-js-sdk'

export default {
  components: {
    navBar,
    commonShare,
  },
  mixins: [userMixin],
  data() {
    return {
      // 分享相关
      showShare: false,
      shareItem: ['posterfriend', 'postermessage', 'save'],
      shareParams: {
        title: '上团课 为心爱的教练打call',
        miniImage: 'https://img.chaolu.com.cn/ACT/teamWarband-202308/250103mini.png',
        path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/group-coach-vote/index&userId=1',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {},
      shareType: 'self',

      ruleShow: false,
      mxShow: false,
      voteShow: false,
      voteRecord: [],
      curTeacher: {},

      activityInfo: {},
      cityList: [
        { text: '福州', id: '3' },
        { text: '厦门', id: '036652995ac5451fbb6791c3491e1c2d' },
        { text: '广州', id: '818447834079563776' },
      ],
      curCity: {},
      callTeacher: undefined,
      rule: `
1、活动期间，用户每上完一节团课即可获得一张【打CALL券】，为参赛教练投票；

2、投票时间：2025年1月7日-1月19日，逾期作废，请及时使用；

3、票券无实际金额，不可折现，不可赠送转让；

4、活动结束后，将根据打CALL票数进行排名，排名最高的教练奖获得【团课人气王】称号。`,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrallFun)
  },
  async created() {
    initBack()
    hideAppBackBtn()
    await this.$getAllInfo(['userId', 'cityId'])

    // 为ta投票 进来弹起投票弹窗
    this.voteCityId = getParam().voteCityId
    if (this.voteCityId) {
      this.curCity = this.cityList.find((item) => item.id == this.voteCityId) || this.cityList[0]
    } else {
      this.curCity = this.cityList.find((item) => item.id == this.cityId) || this.cityList[0]
    }

    console.log(this.curCity, this.cityId);

    await this.getActivity()

    this.voteTeacherId = getParam().voteTeacherId
    if (this.voteTeacherId) {
      this.curTeacher = this.activityInfo.teacherList.find((item) => item.teacherId === this.voteTeacherId)
      this.voteShow = true
    }

    window.scrollTo(0, 0)
    const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/group-coach-vote/index`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
    wx.miniProgram.postMessage({
      data: {
        type: 'share',
        shareImage: this.shareParams.miniImage,
        shareTitle: this.shareParams.title,
        shareUrl: this.shareParams.path,
      },
    })
    this.getVoteRecord()
  },

  methods: {
    newAppBack,
    appType,
    scrallFun() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    },
    initQr() {

      return this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: `group-vote-202501`,
            userId: this.userId,
            voteTeacherId: this.shareType === 'ta' ? this.curTeacher.teacherId : '',
            voteCityId: this.shareType === 'ta' ? this.curCity.id : '',
            webUrl: `${window.location.origin}/#/superdeer-activity/group-coach-vote/index`,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },

    getActivity() {
      return this.$axios
        .post(this.baseURLApp + '/call/activity/index', {
          userId: this.userId,
          cityId: this.curCity.id,
        })
        .then((res) => {
          this.activityInfo = res.data
        })
    },
    pullShare(item) {
      this.curTeacher = item
      this.shareFun('ta')
    },
    async shareFun(type) {
      this.shareType = type
      await this.initQr()
      this.showShare = true
      if (this.shareType === 'self') {
        this.shareItem = ['minifriend', 'postermessage', 'save']
      } else {
        this.shareItem = ['posterfriend', 'postermessage', 'save']
      }
    },
    vote(item) {
      this.voteShow = true
      this.curTeacher = item
    },
    voteConfirm(isVoteAll) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(this.baseURLApp + '/call/activity/vote', {
          userId: this.userId,
          teacherId: this.curTeacher.teacherId,
          teacherName: this.curTeacher.teacherName,
          isVoteAll,
          cityId: this.curCity.id,
        })
        .then((res) => {
          this.voteShow = false
          this.getActivity()
          this.getVoteRecord()
          this.$toast('投票成功~')
        })
    },
    getVoteRecord() {
      this.$axios
        .post(this.baseURLApp + '/call/activity/userVoteDetail', {
          userId: this.userId,
          cityId: this.curCity.id
        })
        .then((res) => {
          this.voteRecord = res.data
        })
    },

    chooseCity(city) {
      this.curCity = city
      this.getActivity()
    },
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.page-content {
  background-color: #f5f5f5;
  min-height: 100vh;
  background-image: url(https://img.chaolu.com.cn/ACT/teamWarband-202308/250103b.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 732px;
  position: relative;

  .rule-btn,
  .mx-btn {
    position: absolute;
    right: 0px;
    top: 339px;
    height: 56px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 28px 0px 0px 28px;
    padding: 0 18px 0 25px;
    color: #fff;
  }
  .mx-btn {
    top: 427px;
  }
  .less-ticket {
    position: absolute;
    left: 299px;
    top: 530px;
    color: #f83776;
  }
  .list-content {
    padding: 0px 32px 104px;
    .rank {
      background: linear-gradient(180deg,#ffdadf, #fff 20vw);
      padding: 32px 24px 24px;
      border-radius: 44px;
      .city-box {
        width: 608px;
        height: 80px;
        background: linear-gradient(90deg, #f73775 0%, #fc3e51 100%);
        border-radius: 40px;
        padding: 0 8px;
        .city-item {
          width: 196px;
          height: 64px;
          border-radius: 32px;
          color: #fff;
          &.active {
            background-color: #fff;
            color: #f83776;
          }
        }
      }
      .rank-item {
        width: 100%;
        margin-top: 44px;
        height: 90px;
        .mc {
          width: 86px;
          text-align: center;
          margin-right: 10px;
          color: #7c849b;

          > img {
            width: 36px;
          }
        }

        > span {
          flex-shrink: 0;
        }

        .avt {
          width: 90px;
          height: 90px;

          > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .pull-btn {
          width: 96px;
          height: 44px;
          background: #ffffff;
          border-radius: 24px;
          border: 2px solid #f83776;
          color: #f83776;
        }
        .vote-btn {
          width: 100px;
          height: 48px;
          background: linear-gradient(0deg, #f73775 0%, #fc3e51 100%);
          border-radius: 24px;
          color: #fff;
          margin: 0 0 0 16px;
        }
      }
    }

    .call-box {
      background-color: #fff2ef;
      border-radius: 20px 20px 20px 20px;

      .call {
        display: flex;
        align-items: center;
        height: 94px;
        font-size: 26px;
        padding: 0px 28px;

        .icon {
          width: 48px;
          height: 48px;
        }

        .avt {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}

.rule-title {
  height: 140px;
  background-image: linear-gradient(180deg, #ffdadf, #ffffff);
  text-align: center;
  line-height: 140px;
}

.rule-box {
  padding: 0px 52px 40px;
  list-style: 1.5;
  height: 30vh;
  overflow: scroll;
  white-space: pre-wrap;
  text-align: justify;
}
.pop-radius {
  border-radius: 24px 24px 0px 0px;

  &.close {
    /deep/.van-popup__close-icon--top-right {
      top: 44px;
    }
  }
}
.vote-popup {
  background-color: transparent !important;
  overflow-y: visible !important;
  .vote-box {
    width: 610px;
    height: 492px;
    background: linear-gradient(0deg, #fff8f9 0%, #ffffff 100%);
    border-radius: 40px;
    .avator {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 8px solid #fff;
      margin: -56px 0 0;
    }
    .name {
      margin: 20px 0 0;
    }
    .say {
      margin: 20px 0 0;
      text-align: center;
      line-height: 40px;
      background-image: url(https://img.chaolu.com.cn/ACT/teamWarband-202308/250103douhao.png);
      background-size: 100% auto;
      background-position: 0 8px;
      background-repeat: no-repeat;
      width: 452px;
    }
    .tip {
      color: #f83776;
      margin: 28px 0 0;
    }
    .button-box {
      margin: 34px 0 0;
      width: 100%;
      padding: 0 40px;
      .button-1 {
        width: 251px;
        height: 92px;
        background: #fff8f9;
        border-radius: 48px;
        border: 2px solid #f83776;
        color: #f83776;
      }
      .button-2 {
        width: 255px;
        height: 96px;
        background: linear-gradient(90deg, #f73775 0%, #fc3e51 100%);
        border-radius: 48px;
        color: #fff;
      }
    }
  }
  .close {
    width: 60px;
    display: block;
    margin: 50px auto 0;
  }
}

.box-title > .title {
  height: 98px;
  line-height: 108px;
  font-size: 32px;
  font-weight: 600;
  border-bottom: 2px solid #f5f5f5;
  text-align: center;
}

.mx-pop {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  .red {
    color: #f03c18;
  }
  .less {
    text-align: center;
    color: #666;
  }
  .pop-title {
    text-align: center;
    height: 116px;
    position: relative;

    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, #FFDADF 100%);
    .ts {
      position: relative;
      transform-style: preserve-3d;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        left: -6px;
        bottom: -2px;
        width: 154px;
        height: 18px;
        background: linear-gradient(270deg, rgba(255, 232, 206, 0) 0%, #FD86AD 100%);
        border-radius: 38px;
        transform: translateZ(-10px);
        z-index: -1;
      }
    }

    .pop-close {
      position: absolute;
      right: 32px;
      top: 38px;
      width: 44px;
      height: 44px;
      line-height: 44px;
      border-radius: 50%;
      background: rgba(39, 40, 49, 0.2);
      font-size: 28px;
      text-align: center;
    }
  }

  .records {
    max-height: 1050px;
    min-height: 500px;
    overflow: auto;
    padding: 12px 32px;
    .item {
      background-color: #fff;
      padding: 28px 0;
      border-bottom: 2px solid #ECECEC;
      .t {
        div:nth-child(1) {
          margin: 0 16px 0 0;
        }
        .p2 {
          margin: 18px 0 0;
        }
      }
    }
  }

  .empty {
    margin: 120px 0 160px;
    text-align: center;
    color: #6c727a;
    img {
      width: 320px;
      margin: 0 0 12px;
    }
  }
}

.r-radius {
  border-radius: 12px;
}

@bei: 1px;

.canvascss {
  position: fixed;
  bottom: -200%;
  // top: 0px;
  width: @bei*654;
  height: @bei*1160;
  margin: 0 auto;
  box-sizing: border-box;

  .canvascss_bg {
    width: 100%;
    height: 100%;
  }

  .canvascss_left {
    position: absolute;
    left: @bei*32;
    bottom: @bei*36;
    z-index: 1;
    width: @bei*100;
    height: @bei*100;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .canvascss_name {
    position: absolute;
    left: @bei*156;
    bottom: 8vw;
    z-index: 1;
    width: @bei*300;
    .p2 {
      margin: 6px 0 0;
    }
  }

  .canvascss_right {
    position: absolute;
    right: @bei*32;
    bottom: @bei*19;
    width: @bei*114;
      height: @bei*114;
      border: 6px solid #FF375B;
      border-radius: @bei*14;
  }
}
</style>
